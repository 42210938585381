<template>
  <div class="flex justify-center items-center" style="height:50%">
    <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
      <div class="text-center my-4">
        <h2 class="text-lg font-bold">
          {{ update ? 'Editar Solicitud' : 'Crear Solicitud' }}
        </h2>
      </div>
      <div class="flex flex-wrap content-around">
        <div class="w-full flex flex-col items-center py-5 px-2 ">
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm">Tipo de solicitud</label>
              <dropdown
                placeholder="Tipo de solicitud"
                :options="tiposProcesos"
                option-label="name"
                option-value="id"
                v-model="model.tipo"
                @change="onChangeTipoSolicitud"
                class="w-full"
              >
                <template #option="slotProps">
                  <div>{{ slotProps.option.name }}</div>
                </template>
              </dropdown>
              <MessageError :text="errors.tipo"/>
            </div>
            <div class="mb-4">
              <label class="font-medium text-sm">Seleccione una opción</label>
              <dropdown
                :placeholder="'Seleccione una opcion de ' + tipoName"
                :options="dataDropdown"
                option-label="nombre"
                option-value="id"
                v-model="model.base"
                class="w-full"
              >
                <template #option="slotProps">
                  <div class="flex">
                    <div class="w-5/12" v-if="slotProps.option.titleUser">
                      <p style="color: #7F7E81" class="text-xs">
                        {{ slotProps.option.titleUser }}
                      </p>
                      <p class="text-normal">
                        {{ slotProps.option.user }}
                      </p>
                    </div>
                    <div class="w-2/12">
                      <p style="color: #7F7E81" class="text-xs">
                        {{ slotProps.option.titleId }}
                      </p>
                      <p class="text-normal">
                        {{ slotProps.option.id }}
                      </p>
                    </div>
                    <div class="w-5/12">
                      <p style="color: #7F7E81" class="text-xs">
                        {{ slotProps.option.title }}
                      </p>
                      <p class="text-normal">
                        {{ slotProps.option.nombre.slice(0, 30) }}...
                      </p>
                    </div>
                  </div>
                </template>
              </dropdown>
              <MessageError :text="errors.base"/>
            </div>
          </div>
          <div class="px-2 w-full flex flex-col">
            <div class="mb-2">
              <label class="font-medium text-sm">Solicitud</label>
              <Textarea
                v-model="model.solicitud"
                placeholder="Solicitud"
                class="w-full"/>
              <MessageError :text="errors.solicitud"/>
            </div>
          </div>
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm">Fecha de vencimiento</label>
              <input class="p-inputtext w-full"
                    type="datetime-local"
                    v-model="model.fechaVencimiento"
              >
              <MessageError :text="errors.fechaVencimiento"/>
            </div>
          </div>
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm">Seleccione a quien se va a solicitar</label>
              <Dropdown class="w-full"
                v-model="model.solicitadoId"
                :options="usuarios"
                optionLabel="fullName"
                optionValue="id"
                placeholder="Seleccione a quien se va a solicitar"
                :filter="true"
                @change="onchangeSolicitado"
              />
              <MessageError :text="errors.solicitadoId"/>
            </div>
          </div>
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm">Correo del solicitado</label>
              <InputText v-model="model.email" class="w-full"/>
              <MessageError :text="errors.email"/>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-between px-2 mb-9">
          <Button label="Cancelar"
                  class="bg-blue-900 p-button-outlined"
                  style="width: 244px;"
                  @click="$router.go(-1)"
          />
          <template v-if="!update">
            <Button label="Crear"
                    class="bg-blue-900"
                    style="width: 244px;"
                    type="button"
                    @click="handleNext"
            />
          </template>
          <template v-else>
            <Button label="Actualizar"
                    class="bg-blue-900"
                    style="width: 244px;"
                    type="button"
                    @click="handleNext"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tiposProcesosStore from '../../../../store/tiposProcesos.store'
import { onMounted, ref } from 'vue'
import TutelaService from '@/apps/pharmasan/juridica/procesosJuridicos/services/tutela.service'
import IncidenciasService from '@/apps/pharmasan/juridica/procesosJuridicos/services/incidencias.service'
import FallosService from '@/apps/pharmasan/juridica/procesosJuridicos/services/fallos.service'
import RespuestasService from '@/apps/pharmasan/juridica/procesosJuridicos/services/respuestas.service'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'
import DesacatosService from '../../../../services/desacatos.service'
import DerechosPeticionService from '../../../../services/derechosPeticion.service'
import ProcesosAdministrativosService from '../../../../services/procesosAdministrativos.service'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import solicitudesStore from '../../../../store/solicitudes.store'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'crearSolicitud',
  setup () {
    const update = ref(false)
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const _UserService = new UserSerivce()
    const _TutelaService = new TutelaService()
    const _IncidenciasService = new IncidenciasService()
    const _ProcesosAdministrativosService = new ProcesosAdministrativosService()
    const _FallosService = new FallosService()
    const _RespuestasService = new RespuestasService()
    const _DerechosPeticionService = new DerechosPeticionService()
    const _DesacatosService = new DesacatosService()

    const tipoName = ref('')
    const dataDropdown = ref([])
    const usuarios = ref([])
    const tiposProcesos = ref([])

    /** state **/
    const schema = yup.object({
      tipo: yup.number().required('El campo es requerido').label(''),
      base: yup.number().required('El campo es requerido').label(''),
      solicitud: yup.string().nullable().required('El campo es requerido').label(''),
      solicitadoId: yup.number().required('El campo es requerido').label(''),
      email: yup.string().email().required('El campo es requerido').label(''),
      fechaVencimiento: yup.string().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('tipo')
    useField('base')
    useField('solicitud')
    useField('solicitadoId')
    useField('email')
    useField('fechaVencimiento')
    /** data **/
    const suggestions = ref([])
    const selectedJuzgado = ref(null)
    /** computed **/
    /** methodos **/
    const handleNext = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      if (!update.value) {
        solicitudesStore.dispatch('createSolicitud', values).then(({ data }) => {
          if (data.error) {
            toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al crear solicitud', life: 3000 })
          } else {
            Swal.fire({
              title: 'Exito',
              text: 'Solicitud creada con exito',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                router.go(-1)
              }
            })
          }
        })
      } else {
        const { id } = route.params
        delete values.id
        values.id = parseInt(id)
        solicitudesStore.dispatch('updateSolicitud', values).then(({ data }) => {
          if (data.error) {
            toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al actualizar la solicitud', life: 3000 })
          } else {
            Swal.fire({
              title: 'Exito',
              text: 'Solicitud actualizada con exito',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                router.go(-1)
              }
            })
          }
        })
      }
    })

    const onChangeTipoSolicitud = ({ value }) => {
      if (value === 1) {
        tipoName.value = 'tutelas'
        _TutelaService.getPaginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observaciones,
              titleId: 'ID:',
              id: e.id,
              titleUser: 'Nombre:',
              user: e.accionanteFullname
            }
          })
        })
      } else if (value === 2) {
        tipoName.value = 'incidentes'
        _IncidenciasService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 3) {
        tipoName.value = 'fallos'
        _FallosService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 4) {
        tipoName.value = 'respuestas'
        _RespuestasService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 5) {
        tipoName.value = 'derechos de petición'
        _DerechosPeticionService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              titleUser: 'Persona:',
              user: e.personaName,
              titleId: 'ID:',
              id: e.id,
              title: 'Asunto:',
              nombre: e.asunto
            }
          })
        })
      } else if (value === 6) {
        tipoName.value = 'sanciones'
        _DesacatosService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              title: 'Observaciones:',
              nombre: e.observacion,
              titleId: 'ID:',
              id: e.id
            }
          })
        })
      } else if (value === 10) {
        tipoName.value = 'procesos administrativos'
        _ProcesosAdministrativosService.paginate().then(({ data }) => {
          dataDropdown.value = data.rows.map((e) => {
            return {
              titleUser: 'Persona:',
              user: e.personaName,
              titleId: 'ID:',
              id: e.id,
              title: 'Asunto:',
              nombre: e.asunto
            }
          })
        })
      }
    }

    const onchangeSolicitado = ({ value }) => {
      if (!value) {
        model.email = null
      } else {
        const { email } = usuarios.value.find(x => x.id === value)
         model.email = email
      }
    }

    /** hooks **/
    onMounted(async () => {
      tiposProcesosStore.dispatch('getPaginate').then(({ data }) => {
        tiposProcesos.value = data.rows
      })
      await _UserService.getAll().then(({ data }) => {
        usuarios.value = data
      })
      if (route.query.tipo) {
        model.tipo = parseInt(route.query.tipo)
        if (parseInt(route.query.tipo) === 1) {
          _TutelaService.getPaginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                title: 'Observaciones:',
                nombre: e.observaciones,
                titleId: 'ID:',
                id: e.id,
                titleUser: 'Nombre:',
                user: e.accionanteFullname
              }
            })
          })
        } else if (parseInt(route.query.tipo) === 5) {
          _DerechosPeticionService.paginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                titleUser: 'Persona:',
                user: e.personaName,
                titleId: 'ID:',
                id: e.id,
                title: 'Asunto:',
                nombre: e.asunto
              }
            })
          })
        } else if (parseInt(route.query.tipo) === 10) {
          _ProcesosAdministrativosService.paginate().then(({ data }) => {
            dataDropdown.value = data.rows.map((e) => {
              return {
                titleUser: 'Persona:',
                user: e.personaName,
                titleId: 'ID:',
                id: e.id,
                title: 'Asunto:',
                nombre: e.asunto
              }
            })
          })
        }
        model.base = parseInt(route.query.base)
        model.provisionalidad = route.query.provisionalidad
      }
      if (route.params.id) {
        update.value = true
        solicitudesStore.dispatch('findSolicitud', route.params.id).then((data) => {
          model.tipo = data.tipo
          model.base = data.base
          model.solicitud = data.solicitud
          model.solicitadoId = data.solicitadoId
          model.fechaVencimiento = dayjs(data.fechaVencimiento).format('YYYY-MM-DDTHH:mm')
          if (data.tipo === 1) {
            tipoName.value = 'tutelas'
            _TutelaService.getPaginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observaciones,
                  titleId: 'ID:',
                  id: e.id,
                  titleUser: 'Nombre:',
                  user: e.accionanteFullname
                }
              })
            })
          } else if (data.tipo === 2) {
            tipoName.value = 'incidentes'
            _IncidenciasService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 3) {
            tipoName.value = 'fallos'
            _FallosService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 4) {
            tipoName.value = 'respuestas'
            _RespuestasService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 5) {
            tipoName.value = 'derechos de petición'
            _DerechosPeticionService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  titleUser: 'Persona:',
                  user: e.personaName,
                  titleId: 'ID:',
                  id: e.id,
                  title: 'Asunto:',
                  nombre: e.asunto
                }
              })
            })
          } else if (data.tipo === 6) {
            tipoName.value = 'sanciones'
            _DesacatosService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  title: 'Observaciones:',
                  nombre: e.observacion,
                  titleId: 'ID:',
                  id: e.id
                }
              })
            })
          } else if (data.tipo === 10) {
            tipoName.value = 'procesos administrativos'
            _ProcesosAdministrativosService.paginate().then(({ data }) => {
              dataDropdown.value = data.rows.map((e) => {
                return {
                  titleUser: 'Persona:',
                  user: e.personaName,
                  titleId: 'ID:',
                  id: e.id,
                  title: 'Asunto:',
                  nombre: e.asunto
                }
              })
            })
          }
        })
      }
    })

    return {
      errors,
      model,
      handleNext,
      suggestions,
      selectedJuzgado,
      onChangeTipoSolicitud,
      tipoName,
      dataDropdown,
      usuarios,
      update,
      onchangeSolicitado,
      tiposProcesos
    }
  }
}
</script>

<style scoped>

</style>
